import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import '@warp-ds/icons/elements/oikotie-light-42';
import '@warp-ds/icons/elements/sofa-42';
import '@warp-ds/icons/elements/link-external-16';
import '@warp-ds/icons/elements/chevron-right-16';
import '@warp-ds/icons/elements/job-42';
import '@warp-ds/icons/elements/minivan-42';
import '@warp-ds/icons/elements/real-estate-42';
import '@warp-ds/icons/elements/airplane-42';
import '@warp-ds/icons/elements/sailboat-42';
import '@warp-ds/icons/elements/motorcycle-42';
import '@warp-ds/icons/elements/cabin-42';
import '@warp-ds/icons/elements/phone-badge-check-42';
import '@warp-ds/icons/elements/honk-light-42';
import '@warp-ds/icons/elements/nettbil-light-42';
import '@warp-ds/icons/elements/remppatori-light-42';
import '@warp-ds/icons/elements/chair-42';
import '@warp-ds/icons/elements/shirt-42';
import '@warp-ds/icons/elements/stroller-42';
import '@warp-ds/icons/elements/ice-skater-42';
import '@warp-ds/icons/elements/paw-42';
import '@warp-ds/icons/elements/guitar-bat-42';
import '@warp-ds/icons/elements/car-front-42';
import '@warp-ds/icons/elements/smart-phone-42';
import '@warp-ds/icons/elements/tools-42';
import '@warp-ds/icons/elements/car-part-42';
import '@warp-ds/icons/elements/vase-42';
import '@warp-ds/icons/elements/autovex-light-42';
import '@warp-ds/icons/elements/geometric-shapes-42';
import '@warp-ds/icons/elements/tractor-42';
import '@warp-ds/icons/elements/store-front-42';
import pulse from '@finn-no/pulse-sdk';
const ifVal = (condition, val) => (condition ? val : '');
const safe = (val) => val || '';
const replaceSpacesWithDashes = (value) => safe(value).replace(/\s+/g, '-');

/*
The Market Icons component contains the MPNB, and the explore more button (for the full category explorer) used in Tori and DBA.
*/
export class MarketIcons extends WarpElement {
    static styles = [
        ...WarpElement.styles, // Adds the global styles
        css`
            .market-grid__item a {
                padding: 16px 4px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            /*
            Set hover effects for grid items.
            Uses the Warp colors defined here: https://warp-ds.github.io/css-docs/icon-color#setting-the-icon-color.
            */
            .market-grid__item a:hover > :nth-child(1) {
                color: var(--w-s-color-icon-subtle-hover);
            }

            .market-grid__item a:hover > :nth-child(2) {
                color: var(--w-s-color-text-subtle);
            }

            .market-grid__item a[data-external]:hover > :nth-child(1) {
                opacity: 0.7;
            }

            @media (max-width: 479px) {
                .market-grid__item a {
                    font-size: 12px;
                    line-height: 16px;
                    padding: 8px 4px;
                }

                .overflow-gradient {
                    background: linear-gradient(90deg, rgba(214, 214, 214, 0) 0%, rgba(121, 121, 121, 0) 76%, rgba(255, 255, 255, 1) 100%);
                    width: calc(100% - 16px);
                    /* Set specific height for the content. */
                    height: 168px;
                    position: absolute;
                    pointer-events: none;
                }

                .scrollable-container {
                    width: 100vw;
                    overflow-x: visible;
                    transform: translateX(-16px);
                }

                .scrollable-container > div {
                    padding-right: 24px;
                }
            }
            .icon-color-finn {
                color: #38bdf8;
            }

            .icon-color-tori {
                color: #f75159;
            }

            .two-lines {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            *,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.focus\\:\\[--w-outline-offset\\:-2px\\]:focus{--w-outline-offset:-2px}.no-underline,.hover\\:no-underline:hover,.focus\\:no-underline:focus,.active\\:no-underline:active{text-decoration:none}.focus\\:focusable:focus{outline:2px solid var(--w-s-color-border-focus);outline-offset:var(--w-outline-offset,1px)}.overflow-x-auto{overflow-x:auto}.right-12{right:1.2rem}.static{position:static}.s-text-static{color:var(--w-s-color-text-static)}.hover\\:s-text-subtle:hover{color:var(--w-s-color-text-subtle)}.s-icon-disabled{color:var(--w-s-color-icon-disabled)}.w-auto{width:auto}.h-\\[28\\]{height:2.8rem}.ml-6{margin-left:.6rem}.mt-8{margin-top:.8rem}.pt-6{padding-top:.6rem}.visible{visibility:visible}.text-ellipsis{text-overflow:ellipsis}.scale-90{--w-scale-x:.9;--w-scale-y:.9;transform:translateX(var(--w-translate-x))translateY(var(--w-translate-y))translateZ(var(--w-translate-z))rotate(var(--w-rotate))rotateX(var(--w-rotate-x))rotateY(var(--w-rotate-y))rotateZ(var(--w-rotate-z))skewX(var(--w-skew-x))skewY(var(--w-skew-y))scaleX(var(--w-scale-x))scaleY(var(--w-scale-y))scaleZ(var(--w-scale-z))}.text-12{font-size:var(--w-font-size-xs);line-height:var(--w-line-height-xs)}@media (min-width:768px){.md\\:text-14{font-size:var(--w-font-size-s);line-height:var(--w-line-height-s)}};
        `,
    ];

    // Fade out the gradient div as the category scroll position approaches the end.
    // This is only visible on mobile devices.
    setupMPNBGradientBehavior() {
        // Get div parameters used for fadeout scroll setup.
        function getScrollParameters(element) {
            const { scrollLeft, scrollWidth, clientWidth } = element;

            const width = scrollWidth - clientWidth;

            const fraction = (width - scrollLeft) / 50;

            return { width, fraction, scrollLeft };
        }

        const gradientDiv = this.shadowRoot?.querySelector('.overflow-gradient');

        const categoryDiv = this.shadowRoot?.querySelector('.scrollable-container>div');

        if (gradientDiv && categoryDiv) {
            // Setup scrolling.
            categoryDiv.addEventListener('scroll', (e) => {
                const { width, fraction, scrollLeft } = getScrollParameters(e.target);

                // Let the opacity approach 0 as the scroll position moves to the end.
                if (fraction <= 1) {
                    gradientDiv.style.opacity = fraction;
                }

                // Use scroll left to reset the opacity when the scroll position is below a threshold, to ensure opacity reset during quick scrolling to the left.
                if (scrollLeft / width < 0.7) {
                    gradientDiv.style.opacity = 1;
                }
            });
        }
    }

    static properties = {
        markets: { type: Array, attribute: 'markets', reflect: true },
        brand: { type: String, attribute: 'brand', reflect: true },
        showCategoryExplorer: { type: Boolean, attribute: 'show-category-explorer', reflect: true },
        exploreMore: { type: Object, attribute: 'explore-more', reflect: true },
    };

    connectedCallback() {
        super.connectedCallback();

        if (window.innerWidth < 480) {
            this.setupMPNBGradientBehavior();
        }
    }

    trackMarketLinkClick(trackingData) {
        pulse.trackEvent({
            type: 'Click',
            intent: 'Open',
            name: trackingData.name,
            object: {
                id: trackingData.object.id,
                type: 'UIElement',
                elementType: trackingData.object.elementType,
                position: trackingData.object.position,
            },
            target: trackingData.target,
            vertical: {
                '@type': trackingData.vertical.type,
                name: trackingData.vertical.name,
            },
        });
    }

    // Explore more button, under the MPNB, that links to the category explorer.
    _exploreMoreButton() {
        const { url, tracking, label } = this.exploreMore;

        return html`<a
            class="flex justify-end pt-6 hover:s-text-subtle"
            href=${url}
            data-explore-more-button
            @click=${() => this.trackMarketLinkClick(tracking)}
        >
            ${label}
            <w-icon-chevron-right-16 class="scale-90 h-16 ml-6 self-center"></w-icon-chevron-right-16>
        </a>`;
    }

    // Market item html.
    _market({ icon, label, destinations, force_external, tracking } = {}, index = 0, brand = 'TORI') {
        const iconName = icon.name;

        brand = brand?.toLowerCase();

        // Dynamic tags (with unsafeStatic) apparently don't work with SSR currently: https://github.com/lit/lit/issues/2246.
        // For that reason, we return html tags here.
        const icons = {
            'sofa-42': () => html`<w-icon-sofa-42 class="icon-color-${brand} h-[28]"></w-icon-sofa-42>`,
            'chair-42': () => html`<w-icon-chair-42 class="icon-color-${brand} h-[28]"></w-icon-chair-42>`,
            'shirt-42': () => html`<w-icon-shirt-42 class="icon-color-${brand} h-[28]"></w-icon-shirt-42>`,
            'stroller-42': () => html`<w-icon-stroller-42 class="icon-color-${brand} h-[28]"></w-icon-stroller-42>`,
            'ice-skater-42': () => html`<w-icon-ice-skater-42 class="icon-color-${brand} h-[28]"></w-icon-ice-skater-42>`,
            'paw-42': () => html`<w-icon-paw-42 class="icon-color-${brand} h-[28]"></w-icon-paw-42>`,
            'guitar-bat-42': () => html`<w-icon-guitar-bat-42 class="icon-color-${brand} h-[28]"></w-icon-guitar-bat-42>`,
            'oikotie-light-42': () => html`<w-icon-oikotie-light-42 class="icon-color-${brand} h-[28]"></w-icon-oikotie-light-42>`,
            'remppatori-light-42': () => html`<w-icon-remppatori-light-42 class="icon-color-${brand} h-[28]"></w-icon-remppatori-light-42>`,
            'car-front-42': () => html`<w-icon-car-front-42 class="icon-color-${brand} h-[28]"></w-icon-car-front-42>`,
            'smart-phone-42': () => html`<w-icon-smart-phone-42 class="icon-color-${brand} h-[28]"></w-icon-smart-phone-42>`,
            'tools-42': () => html`<w-icon-tools-42 class="icon-color-${brand} h-[28]"></w-icon-tools-42>`,
            'car-part-42': () => html`<w-icon-car-part-42 class="icon-color-${brand} h-[28]"></w-icon-car-part-42>`,
            'vase-42': () => html`<w-icon-vase-42 class="icon-color-${brand} h-[28]"></w-icon-vase-42>`,
            'autovex-light-42': () => html`<w-icon-autovex-light-42 class="icon-color-${brand} h-[28]"></w-icon-autovex-light-42>`,
            'real-estate-42': () => html`<w-icon-real-estate-42 class="icon-color-${brand} h-[28]"></w-icon-real-estate-42>`,
            'airplane-42': () => html`<w-icon-airplane-42 class="icon-color-${brand} h-[28]"></w-icon-airplane-42>`,
            'sailboat-42': () => html`<w-icon-sailboat-42 class="icon-color-${brand} h-[28]"></w-icon-sailboat-42>`,
            'motorcycle-42': () => html`<w-icon-motorcycle-42 class="icon-color-${brand} h-[28]"></w-icon-motorcycle-42>`,
            'cabin-42': () => html`<w-icon-cabin-42 class="icon-color-${brand} h-[28]"></w-icon-cabin-42>`,
            'minivan-42': () => html`<w-icon-minivan-42 class="icon-color-${brand} h-[28]"></w-icon-minivan-42>`,
            'tractor-42': () => html`<w-icon-tractor-42 class="icon-color-${brand} h-[28]"></w-icon-tractor-42>`,
            'store-front-42': () => html`<w-icon-store-front-42 class="icon-color-${brand} h-[28]"></w-icon-store-front-42>`,
            'van-42': () => html`<w-icon-minivan-42 class="icon-color-${brand} h-[28]"></w-icon-minivan-42>`,
            'job-42': () => html`<w-icon-job-42 class="icon-color-${brand} h-[28]"></w-icon-job-42>`,
            'phone-badge-check-42': () =>
                html`<w-icon-phone-badge-check-42 class="icon-color-${brand} h-[28]"></w-icon-phone-badge-check-42>`,
            'nettbil-light-42': () => html`<w-icon-nettbil-light-42 class="icon-color-${brand} h-[28]"></w-icon-nettbil-light-42>`,
            'geometric-shapes-42': () => html`<w-icon-geometric-shapes-42 class="icon-color-${brand} h-[28]"></w-icon-geometric-shapes-42>`,
        };

        return html`<div class="market-grid__item text-center relative w-auto" id="parentElement">
            <a
                class="py-8 flex flex-col no-underline hover:no-underline active:no-underline focus:no-underline focus:focusable focus:[--w-outline-offset:-2px]"
                href=${safe(destinations.url)}
                data-icon-name=${replaceSpacesWithDashes(label)}
                @click=${() => this.trackMarketLinkClick(tracking)}
                data-external=${force_external}
                target=${force_external ? '"_blank"' : null}
                id=${index === 0 ? 'first-market-link' : null}
            >
                ${icons[iconName]?.()}

                <span class="s-text-static text-12 mt-8 md:text-14 overflow-hidden text-ellipsis">${safe(label)}</span>
            </a>
            <w-icon-link-external-16
                class="${ifVal(!force_external, 'hidden ')}absolute right-12 top-8 pointer-events-none s-icon-disabled"
            ></w-icon-link-external-16>
        </div>`;
    }

    render() {
        if (this.markets?.length > 0) {
            const markets = this.markets;
            // Get nr of columns, rounding up.
            const colNr = Math.ceil(markets.length / 2);

            return html`
                <!-- Gradient (fadeout) overlay div. -->
                <div class="overflow-gradient z-10"></div>

                <!-- Custom container with overflow-x visible, for a scrollable div that extends outside margins. -->
                <div class="scrollable-container">
                    <div class="grow bg-transparent overflow-x-auto">
                        <!-- MARKET ICONS -->
                        <nav
                            class="grid"
                            style="grid-template-columns: repeat(${colNr}, minmax(10.4rem,1fr));"
                            aria-labelledby="market-grid-title"
                            data-markets-pulse-wrapper
                        >
                            <h2 id="market-grid-title" class="sr-only">
                                ${this.i18n.t({
                                    id: 'title.frontpage.markets',
                                    message: 'Markets',
                                })}
                            </h2>
                            ${markets.map((m, i) => this._market(m, i, this.brand))}
                        </nav>
                        <!-- /MARKET ICONS -->
                    </div>
                </div>
                ${this.showCategoryExplorer && this.exploreMore ? this._exploreMoreButton() : ''}
            `;
        }
        return html`<div></div>`;
    }
}

if (!customElements.get('market-icons')) {
    customElements.define('market-icons', MarketIcons);
}
